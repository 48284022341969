import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------

export const DARK_MODE = 'DARK_MODE';
export const LIGHT_MODE = 'LIGHT_MODE';
export const DARK2_MODE = 'DARK2_MODE';
export const DARK3_MODE = 'DARK3_MODE';

// ------------------------------------
// Actions
// ------------------------------------
const turnDark = createAction(DARK_MODE);
const turnLight = createAction(LIGHT_MODE);
const turnDark2 = createAction(DARK2_MODE);
const turnDark3 = createAction(DARK3_MODE);

export const actions = {
  turnDark,
  turnDark2,
  turnLight,
  turnDark3,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  mode: '',
};

export default handleActions(
  {
    DARK_MODE: (state) => {
      return {
        ...state,
        mode: 'midnight',
      };
    },
    DARK2_MODE: (state) => {
      return {
        ...state,
        mode: 'ultraviolet',
      };
    },
    DARK3_MODE: (state) => {
      return{
        ...state,
        mode: 'highContrast'
      } 
    },
    LIGHT_MODE: (state) => {
      return {
        ...state,
        mode: 'cerulian',
      };
    },
  },
  initialState,
);

export const getMode = (state) => state.appearance.mode;
